import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL, isInternalURL } from '@plone/volto/helpers';
import { Button } from 'semantic-ui-react';

export function ButtonBlock({ data, editable, ...props }) {
  const buttonText = data.text || 'NO BUTTON TEXT';
  if (editable) {
    return (
      <Button
        as="a"
        href=""
        onClick={(e) => {
          if (editable) {
            e.preventDefault();
          }
        }}
      >
        {buttonText}
      </Button>
    );
  }
  let url = data.url;

  if (Array.isArray(url)) {
    url = url[0];
  }

  if (typeof url === 'object' && url.hasOwnProperty('@id') && isInternalURL(flattenToAppURL(url['@id']))) {
    return (
      <UniversalLink item={url}>
        <Button as="span">{buttonText}</Button>
      </UniversalLink>
    );
  }
  url = flattenToAppURL(url['@id']);

  return (
    <Button as={UniversalLink} href={url}>
      {buttonText}
    </Button>
  );
}
