import linkSVG from '@plone/volto/icons/link.svg';
import { ButtonBlock } from './ButtonBlock';

const applyConfig = (config) => {
  config.settings = {
    ...config.settings,
    isMultilingual: false,
    supportedLanguages: ['en'],
    defaultLanguage: 'en',
  };

  // Can't name it just button due to the bad CSS in Volto causing a `button` class to get added and breaking the UI
  config.blocks.blocksConfig['buttonLink'] = {
    id: 'buttonLink',
    title: 'Button',
    icon: linkSVG,
    group: 'common',
    restricted: false,
    mostUsed: true,
    sidebarTab: 1,
    view: ButtonBlock,
    blockSchema: {
      title: 'Button schema',
      fieldsets: [
        {
          id: 'default',
          title: 'Default',
          fields: ['text', 'url'],
        },
      ],

      properties: {
        text: {
          title: 'Text',
        },
        url: {
          title: 'Url',
          widget: 'object_browser',
          mode: 'link',
        },
      },
      required: [],
    },
  };

  config.blocks.requiredBlocks = [];

  return config;
};

export default applyConfig;
